import fly from 'flyio';
import config from '@/config';

fly.config.baseURL = `${config.baseUrl}/adcx-api`;

fly.interceptors.response.use(
  (response) => {
    const {
      code,
      decimals,
    } = response.data;
    if (code === 200 || decimals) {
      return response.data;
    }
    console.log('%c require error', 'color: #f00', response.data);
    return Promise.reject(response.data);
  },
  (error) => {
    console.log('%c require error', 'color: #ff0', error);
    return Promise.reject(error);
  },
);

export default fly;
