import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "main-content-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_top_bar = _resolveComponent("top-bar");

  const _component_router_view = _resolveComponent("router-view");

  const _component_bottom_bar = _resolveComponent("bottom-bar");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_top_bar), _createElementVNode("div", _hoisted_1, [_createVNode(_component_router_view), _createVNode(_component_bottom_bar)])], 64);
}