export default {
  address: {
    main: '0xF56eDD8F2CE2728b4fdE69F879bb1c0d470b1194',
    test: '0xd34bec8d4452077d545b47bb973a188aa64b1b0d',
  },
  abi: [{
    inputs: [{
      internalType: 'address payable',
      name: '_feeAddr',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: '_feePercent',
      type: 'uint256',
    }, {
      internalType: 'address[]',
      name: '_coinsArr',
      type: 'address[]',
    }, { internalType: 'address[]', name: '_nftsArr', type: 'address[]' }],
    stateMutability: 'nonpayable',
    type: 'constructor',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'nftAddress', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'coinAddress', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'seller', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'price', type: 'uint256',
    }],
    name: 'Ask',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'nftAddress', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'seller', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }],
    name: 'CancelSellToken',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'FeeAddressTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'Paused',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'nftAddress', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }, {
      indexed: true, internalType: 'address', name: 'creator', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'owner', type: 'address',
    }],
    name: 'SetCreator',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'seller', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'oldFeePercent', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'newFeePercent', type: 'uint256',
    }],
    name: 'SetFeePercent',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'nftAddress', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }, {
      indexed: true, internalType: 'uint256', name: 'royalties', type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'owner', type: 'address',
    }],
    name: 'SetRoyalties',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'nftAddress', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'seller', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'buyer', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'price', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'fee', type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'coin', type: 'address',
    }],
    name: 'Trade',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'Unpaused',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }, {
      indexed: true, internalType: 'address[]', name: 'newCoins', type: 'address[]',
    }],
    name: 'UpdateCoins',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }, {
      indexed: true, internalType: 'address[]', name: 'newNfts', type: 'address[]',
    }],
    name: 'UpdateNfts',
    type: 'event',
  }, {
    inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
    name: 'addCoins',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
    name: 'addNfts',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'buyToken',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }, {
      internalType: 'address',
      name: '_to',
      type: 'address',
    }],
    name: 'buyTokenTo',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'cancelSellToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddr',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'creator',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'feeAddr',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'feePercent',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'getAskData',
    outputs: [{
      components: [{
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'price', type: 'uint256' }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }],
      internalType: 'struct OpenGateMarketV2.AskEntry',
      name: '',
      type: 'tuple',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_nftAddress', type: 'address' }],
    name: 'getAskLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_nftAddress', type: 'address' }],
    name: 'getAsks',
    outputs: [{
      components: [{
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'price', type: 'uint256' }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }],
      internalType: 'struct OpenGateMarketV2.AskEntry[]',
      name: '',
      type: 'tuple[]',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }, {
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }],
    name: 'getAsksByUser',
    outputs: [{
      components: [{
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'price', type: 'uint256' }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }],
      internalType: 'struct OpenGateMarketV2.AskEntry[]',
      name: '',
      type: 'tuple[]',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'getCoins',
    outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'getNfts',
    outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'nft',
    outputs: [{ internalType: 'contract IERC721', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'nftH',
    outputs: [{ internalType: 'contract IERC721Helper', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '', type: 'address' }, {
      internalType: 'address',
      name: '',
      type: 'address',
    }, { internalType: 'uint256', name: '', type: 'uint256' }, {
      internalType: 'bytes',
      name: '',
      type: 'bytes',
    }],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'openForOwner',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'openNFT',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'quoteErc20',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'address',
      name: '_coinAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }, {
      internalType: 'uint256',
      name: '_price',
      type: 'uint256',
    }],
    name: 'readyToSellToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'address',
      name: '_coinAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }, {
      internalType: 'uint256',
      name: '_price',
      type: 'uint256',
    }, {
      internalType: 'address payable',
      name: '_creator',
      type: 'address',
    }, { internalType: 'uint256', name: '_royalties', type: 'uint256' }],
    name: 'readyToSellTokenByOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'address',
      name: '_coinAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }, {
      internalType: 'uint256',
      name: '_price',
      type: 'uint256',
    }],
    name: 'readyToSellTokenV2',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
    name: 'removeCoins',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
    name: 'removeNfts',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddr',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'royalties',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'royaltiesBase',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'royaltiesTop',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddr',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'seller',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'address',
      name: '_coinAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }, {
      internalType: 'uint256',
      name: '_price',
      type: 'uint256',
    }],
    name: 'setCurrentPrice',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_feePercent', type: 'uint256' }],
    name: 'setFeePercent',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bool', name: '_open', type: 'bool' }],
    name: 'setOpenForOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_royalties', type: 'uint256' }],
    name: 'setRoyaltiesTop',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address payable', name: '_feeAddr', type: 'address' }],
    name: 'transferFeeAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }],
};
