import { createApp } from 'vue';
import api from '@/api/api';
import ethers from '@/utils/ethers';
import WalletBox from '@/components/WalletBox';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Vant, { Locale } from 'vant';
import 'vant/lib/index.css';
import enUS from 'vant/es/locale/lang/en-US';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import 'normalize.css';
import 'element-plus/theme-chalk/src/message.scss';
import 'element-plus/theme-chalk/src/loading.scss';
import '@/assets/css/global.scss';
import '@/assets/css/petaverse.css';
import VueI18n from '@/utils/language';

Locale.use('en-US', enUS);
dayjs.extend(isBetween);

const app = createApp(App);

app.config.globalProperties.$ethers = ethers;
app.config.globalProperties.$api = api;
app.config.globalProperties.$dayjs = dayjs;

app.use(VueI18n);
app.use(Vant);
app.use(store);
app.use(router);
app.use(WalletBox);
app.mount('#app');
