import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-038007d1"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "bid-box"
};
const _hoisted_2 = {
  class: "top center"
};
const _hoisted_3 = {
  key: 0,
  class: "input-box center"
};
const _hoisted_4 = ["innerHTML"];
const _hoisted_5 = {
  class: "bottom"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['cover', 'center', $props.customClass])
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString($props.title), 1), !$props.isHtml ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.message), 1)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: "input-box center",
    innerHTML: $props.message
  }, null, 8, _hoisted_4)), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", {
    class: "confirm-btn center",
    onClick: _cache[0] || (_cache[0] = (...args) => $props.confirm && $props.confirm(...args))
  }, "Ok")])])], 2);
}