import TopBar from '@/components/TopBar.vue';
import BottomBar from '@/components/BottomBar.vue';
export default {
  components: {
    TopBar,
    BottomBar
  },

  mounted() {
    setTimeout(() => {
      if (typeof window.ethereum !== 'undefined' && window.ethereum.selectedAddress && this.$ethers.getConnectionActionRecord() !== 0) {
        this.$ethers.connectWallet();
      } else if (localStorage.getItem('walletconnect')) {
        this.$ethers.connectWallet(1);
      }
    }, 500);
  }

};