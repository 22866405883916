import fly from './request';

export default {
  // 获取艺术品
  getArtwork(data) {
    return fly.get('/open/nft/nfts', data);
  },
  // 获取nft详情
  getArtworkDetail(id) {
    return fly.get(`/open/nft/detail/${id}`);
  },
  getTokenData(address) {
    return fly.get(`https://web.dev.hello4am.com/tokens/bsc/${address}/info.json`);
  },
  // 创建NFT信息
  createNft(data) {
    return fly.post('/open/nft/createNft', data);
  },
  // NFT关联tokenId
  bindTokenId(nftId, tokenId, nftAddress) {
    let url = '';
    if (nftAddress) {
      url = `/open/nft/bind/${nftId}/${tokenId}?nftAddress=${nftAddress}`;
    } else {
      url = `/open/nft/bind/${nftId}/${tokenId}`;
    }
    return fly.post(url);
  },
  // 文件上传接口
  uploadFile(data) {
    return fly.post('/public/upload', data);
  },
  // 记录交易
  recordTransactions(data) {
    return fly.post('/open/nft/createEvent', data);
  },
  // 获取交易历史
  getHistory(tokenAddress, tokenId) {
    return fly.get(`/open/nft/buyHistory/${tokenAddress}/${tokenId}`);
  },
  // 注册
  register(data) {
    return fly.post('/auth/opengate/register', null, {
      params: data,
    });
  },
  // 钱包登录
  login(address) {
    return fly.get(`/auth/opengate/loginByAddress/${address}`);
  },
  // 查询上级
  checkStatus(address) {
    return fly.get(`/opengate/referral/checkStatus/${address}`);
  },
  // 绑定邀请码
  bind(address, code) {
    return fly.get(`/opengate/referral/bind/${address}/${code}`);
  },
  // 获取下级
  getReferralUser(address, nickname) {
    return fly.get('/opengate/referral/myReferralUser', {
      address,
      nickname,
    });
  },
  // 我的出售记录
  getSaleRecord(address) {
    return fly.get(`/opengate/referral/soldList/${address}`);
  },
  // 所有nft销售记录
  allSoldRecord(params, token) {
    return fly.get('/opengate/referral/soldListAdmin', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 所有nft销售记录
  exportAllSoldRecord(params, token) {
    return fly.get('/opengate/referral/export', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 我的下级
  getSubUserList(address, token) {
    return fly.get(`/opengate/subUserList/${address}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 用户详情
  getUserDetail(address, token) {
    return fly.get(`/opengate/subUserDetail/${address}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 获取全部关联信息
  getAll(params, token) {
    return fly.get('/opengate/userListWithSub', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 管理员汇总
  getAllOverview() {
    return fly.get('/opengate/sellData');
  },
  collectionOverview(nftAddress, collectionId) {
    return fly.get(`/opengate/sellData/${nftAddress}/${collectionId}`);
  },
  // 获取统计数据
  getOverviewData() {
    return fly.get('/opengate/indexData/all');
  },
  // 获取统计数据
  getOverviewDataBynNftAddress(nftAddress) {
    return fly.get(`/opengate/indexData/${nftAddress}`);
  },
  // collection统计数据
  collectionData(collectionId) {
    return fly.get(`/opengate/indexData/all/${collectionId}`);
  },
  // 获取admin地址列表
  getAdminAddressList(token) {
    return fly.get('/opengate/addressIdentityList', null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 查询nft collection列表
  getCollectionsList(params) {
    return fly.get('/nft/nftCollection/list', params);
  },
  // 获取nft collection详细信息
  getCollectionDetail(id) {
    return fly.get(`/nft/nftCollection/${id}`);
  },
  // 新增nft collection
  createCollection(data, token) {
    return fly.post('/nft/nftCollection/create', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 修改nft collection
  editCollection(id, data, token) {
    return fly.post(`/nft/nftCollection/edit/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 发布Collection
  publishCollection(contract, hash, id, network, token) {
    return fly.post('/nft/nftCollection/publishCollection', {
      contract, hash, id, network,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 获取用户购买的集合
  getUserCollectionsList(address, data, token) {
    return fly.get(`/nft/nftCollection/listOther/${address}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  // 当前用户点赞状态
  getLikeState(id, token) {
    return fly.get(`/nft/nftLike/likeStatus/${id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  like(id, token) {
    return fly.get(`/nft/nftLike/like/${id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  dislike(id, token) {
    return fly.get(`/nft/nftLike/cancelLike/${id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
