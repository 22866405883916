import { mapState } from 'vuex';
export default {
  name: 'TopBar',

  data() {
    return {
      menu: false,
      infoBox: false,
      timer: null
    };
  },

  computed: { ...mapState(['displayAccount', 'Account', 'username', 'myCode', 'admin', 'leader', 'mint']),

    language() {
      return this.$store.language;
    }

  },
  methods: {
    toReferredList() {
      this.menu = false;
      this.$router.push('/referred-list');
    },

    showInfoBox() {
      clearTimeout(this.timer);
      this.infoBox = true;
    },

    hideInfoBox() {
      this.timer = setTimeout(() => {
        this.infoBox = false;
      }, 500);
    },

    toMint() {
      this.menu = false;
      this.$router.push('/mint');
    },

    toHome() {
      this.menu = false;
      this.$router.push('/');
    },

    connectWallet() {
      this.$walletBox().then(() => {
        this.$router.replace(this.$route.fullPath);
      });
    },

    disconnectWallet() {
      this.$ethers.disconnectWallet();
    },

    toMyList() {
      this.menu = false;
      this.$router.push('/user-center/mynft-list');
    },

    totalMenu() {
      this.menu = !this.menu;
    },

    toMyReferral() {
      this.menu = false;
      this.$router.push('/all-referral');
    },

    toCollection() {
      this.menu = false;

      if (this.Account) {
        this.$router.push('/user-center');
      } else {
        this.$walletBox();
      }
    },

    toAuction() {
      this.menu = false;
      this.$router.push('/auction');
    },

    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('setLanguage', lang);
    }

  }
};