import api from '@/api/api';
import { ethers } from 'ethers';
import WalletConnectProvider from '@walletconnect/web3-provider';
import config from '@/config';
import store from '@/store';
import Popup from '@/components/Popup';
import LoginBox from '@/components/Login';
import { ElMessage } from 'element-plus';
import exchangeContractInfo from '@/contracts/exchangeContract';
import nftContractInfo from '@/contracts/nftContract';
import coinContractInfo from '@/contracts/coinContract';
import factoryContractInfo from '@/contracts/factoryContract';
import auctionContractInfo from '@/contracts/auctionContract';
import decimal from '@/utils/decimal';
import bus from '@/utils/bus';

const jsonRpcProvider = new ethers.providers.JsonRpcProvider(config.networkUrl);

const exchangeAddress = exchangeContractInfo.address[config.env].toLowerCase();
const exchangeAbi = exchangeContractInfo.abi;

const auctionAddress = auctionContractInfo.address[config.env];
const auctionAbi = auctionContractInfo.abi;


const oldNftAddress = nftContractInfo.address[config.env].toLowerCase();
const nftAbi = nftContractInfo.abi;

const factoryAddress = factoryContractInfo.address[config.env];

const coinAbi = coinContractInfo.abi;

let account = null;
let provider = null;
let exchangeContract = null;
let readOnlyExchangeContract = null;
// const readOnlyExchangeContractV1 = null;
let auctionContract = null;
let readOnlyAuctionContract = null;
let web3Provider = null;

function getCode() {
  const reg = /(^|&)code=([^&]*)(&|$)/i;
  const r = window.location.search.substring(1)
    .match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return null;
}

// 获取用户钱包地址
function getAccount() {
  return account;
}
function setConnectionActionRecord(status) {
  if (status === 1) {
    bus.$emit('connectSuccess');
  }
  sessionStorage.setItem('connectionActionRecord', status);
}
// 添加监听器
function addListener(target) {
  target.on('accountsChanged', async () => {
    console.log('%c accountsChanged', 'color: #0f0');
    window.location.reload();
  });
  target.on('chainChanged', async () => {
    console.log('%c chainChanged', 'color: #0f0');
    window.location.reload();
  });
  target.on('disconnect', async () => {
    console.log('%c disconnect', 'color: #0f0');
    setConnectionActionRecord(0);
    window.location.reload();
  });
}
async function disconnectWallet() {
  console.log('disconnect');
  if (web3Provider != null) {
    await web3Provider.disconnect();
  }
  setConnectionActionRecord(0);
  provider = null;
  account = null;
  web3Provider = null;
  window.location.reload();
}
// 连接钱包
async function connectWallet(type) {
  if (type === 1) {
    web3Provider = new WalletConnectProvider({
      rpc: { [config.chainId]: config.networkUrl },
      chainId: config.chainId,
      networkId: config.chainId,
      qrcode: true,
    });
    [account] = await web3Provider.enable();
    provider = new ethers.providers.Web3Provider(web3Provider);
    addListener(web3Provider);
  } else if (typeof window.ethereum === 'undefined') {
    Popup('No provider was found', 'Provider Error');
  } else {
    [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    provider = new ethers.providers.Web3Provider(window.ethereum);
    const { chainId } = await provider.getNetwork();
    if (chainId !== config.chainId) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: config.networkInfo,
      });
    }
    addListener(window.ethereum);
  }
  if (account) {
    exchangeContract = new ethers.Contract(exchangeAddress, exchangeAbi, provider.getSigner());
    // exchangeContractV1 = new ethers.Contract(exchangeAddressV1, exchangeAbiV1, provider.getSigner());
    auctionContract = new ethers.Contract(auctionAddress, auctionAbi, provider.getSigner());
    store.commit('setDisplayAccount', account);
    const code = getCode('code');
    let myCode = await api.login(account)
      .then(async (res) => {
        store.commit('setUsername', res.data.username);
        store.commit('setMyCode', res.data.myCode);
        store.commit('setToken', res.data.token);
        await api.getAdminAddressList(res.data.token)
          .then((result) => {
            store.commit('setAddress', result.data);
          });
        return res.data.myCode;
      })
      .catch(() => null);
    if (!myCode) {
      myCode = await LoginBox(account);
      if (!myCode) return;
    }
    const isBind = (await api.checkStatus(account)).data > 0;
    if (!isBind && code && (myCode !== code)) {
      const bindRes = await api.bind(account, code)
        .then(() => true)
        .catch((err) => {
          ElMessage.error(err.msg);
          return false;
        });
      if (!bindRes) return;
    }
    if (myCode !== code) {
      const url = window.location.href.split('#');
      if (url[0].includes('code')) {
        url[0] = url[0].replace(/\?.*/, '');
      }
      window.location.replace(`${url[0]}?code=${myCode}#${url[1]}`);
    }
  }
  console.log('%c account', 'color: #0f0', account);
  console.log('%c exchangeContract', 'color: #0f0', exchangeContract);

  setConnectionActionRecord(1);
}
// 此状态不代表实际状态
function getConnectionActionRecord() {
  const status = sessionStorage.getItem('connectionActionRecord');
  // 未主动点击过断开或连接
  if (status === null || status === undefined || status === '-1') return -1;
  // 以连接
  if (status === '1') {
    return 1;
  }
  // 网站内主动断开
  return 0;
}

// 获取拍卖场仅阅读合约
function getReadOnlyExchangeContract() {
  return new Promise((resolve) => {
    if (!readOnlyExchangeContract) {
      readOnlyExchangeContract = new ethers.Contract(exchangeAddress, exchangeAbi, jsonRpcProvider);
    }
    resolve();
  });
}

// function getReadOnlyExchangeContractV1() {
//   return new Promise((resolve) => {
//     if (!readOnlyExchangeContractV1) {
//       readOnlyExchangeContractV1 = new ethers.Contract(exchangeAddressV1, exchangeAbiV1, jsonRpcProvider);
//     }
//     resolve();
//   });
// }


function getReadOnlyAuctionContract() {
  return new Promise((resolve) => {
    if (!readOnlyAuctionContract) {
      readOnlyAuctionContract = new ethers.Contract(auctionAddress, auctionAbi, jsonRpcProvider);
    }
    resolve();
  });
}

// 查询用户未上架的NFT列表
async function getUserPendingArtwork(nftAddress) {
  const nftContract = new ethers.Contract(nftAddress, nftAbi, provider.getSigner());
  const res = await nftContract.tokensOfOwner(account);
  return res.map((item) => ({
    tokenId: ethers.utils.formatUnits(item, 0),
    price: '',
    state: 1,
  }));
}

// 查询NFT拥有者
async function getNftOwner(nftAddress, tokenId) {
  const contract = new ethers.Contract(nftAddress, nftAbi, jsonRpcProvider);
  const res = await contract.ownerOf(tokenId);
  return res.toLowerCase();
}

// 查询NFT授权
async function getApproved(nftAddress, tokenId) {
  const contract = new ethers.Contract(nftAddress, nftAbi, jsonRpcProvider);
  const res = await contract.getApproved(tokenId);
  return res.toLowerCase();
}

async function getBaseUrl(nftAddress) {
  const contract = new ethers.Contract(nftAddress, nftAbi, jsonRpcProvider);
  const res = await contract.baseURI();
  console.log(`getBaseUrl:${res}`);
  return res.toLowerCase();
}

async function setBaseUrl(nftAddress, baseURI) {
  const contract = new ethers.Contract(nftAddress, nftAbi, jsonRpcProvider);
  const res = await contract.setBaseURI(baseURI);
  console.log(`setBaseUrl:${res}`);
  return res.toLowerCase();
}

// 在交易所查询当前用户上架的nft
async function getAsksByUser(nftAddress) {
  await getReadOnlyExchangeContract();
  const res = await readOnlyExchangeContract.getAsksByUser(account, nftAddress);
  return res.map((item) => ({
    tokenId: ethers.utils.formatUnits(item.tokenId, 0),
    price: '',
    state: 2,
    isOldExchange: true,
  }));
}

// 获取手续费金额
async function getMintFee(nftAddress) {
  const contract = new ethers.Contract(nftAddress, nftAbi, jsonRpcProvider);
  const res = await contract.mintFeeAmount();
  return Number(ethers.utils.formatEther(res));
}

// 获取手续费货币
async function getMintFeeAddress(nftAddress) {
  const contract = new ethers.Contract(nftAddress, nftAbi, jsonRpcProvider);
  return contract.feeERC20Address();
}

// 部署合约并铸造
function deployAndMint(name, creator, tokenURI, tokenRoyalties) {
  const royalties = decimal.multiply(tokenRoyalties, 100).toString();
  const contract = new ethers.Contract(factoryAddress, factoryContractInfo.abi, provider.getSigner());
  console.log('%c name', 'color: #0f0', name);
  console.log('%c creator', 'color: #0f0', creator);
  console.log('%c tokenURI', 'color: #0f0', tokenURI);
  console.log('%c royalties', 'color: #0f0', royalties);
  return contract.deployAndMintNFTDefault(name, name, creator, tokenURI, royalties);
}

// 铸造
function mint(nftAddress, tokenURI, tokenRoyalties, creator, mintFee = 0) {
  const royalties = decimal.multiply(tokenRoyalties, 100).toString();
  const nftContract = new ethers.Contract(nftAddress, nftAbi, provider.getSigner());
  return nftContract.mintByCreator(account, tokenURI, royalties, creator, {
    from: account,
    value: ethers.utils.parseEther(mintFee.toString()),
  });
}

// NFT授权
function approve(nftAddress, tokenId) {
  const nftContract = new ethers.Contract(nftAddress, nftAbi, provider.getSigner());
  return nftContract.approve(exchangeAddress, tokenId);
}

// 获取查询已上架NFT列表
async function getAsks(nftAddress) {
  await getReadOnlyExchangeContract();
  const res = await readOnlyExchangeContract.getAsks(nftAddress);
  return res.map((item) => ({
    tokenId: ethers.utils.formatUnits(item.tokenId, 0),
    price: ethers.utils.formatEther(item.price),
  }));
}

// async function getAsksV1(nftAddress) {
//   await getReadOnlyExchangeContractV1();
//   const res = await readOnlyExchangeContractV1.getAsks(nftAddress);
//   return res.map((item) => ({
//     tokenId: ethers.utils.formatUnits(item.tokenId, 0),
//     price: ethers.utils.formatEther(item.price),
//   }));
// }

// 获取支持货币
async function getCoins() {
  await getReadOnlyExchangeContract();
  return readOnlyExchangeContract.getCoins();
}

// 上架交易所
function transfer(data) {
  const price = ethers.utils.parseEther(`${data.price}`);
  const target = data.nftAddress.toLowerCase() === oldNftAddress.toLowerCase() ? 'readyToSellToken' : 'readyToSellTokenV2';
  return exchangeContract[target](data.nftAddress, data.coinAddress, data.tokenId, price);
}

// 查询用户货币授权
async function getCoinApprove(coinAddress) {
  const contract = new ethers.Contract(coinAddress, coinAbi, provider.getSigner());
  const res = await contract.allowance(account, exchangeAddress);
  return ethers.utils.formatUnits(res.toString());
}

// 用户货币授权
function coinApprove(price, coin, nftAddress) {
  const p = ethers.utils.parseEther(price.toString());
  const contract = new ethers.Contract(coin, coinAbi, provider.getSigner());
  let target = null;
  if (nftAddress) {
    target = nftAddress;
  } else {
    target = exchangeAddress;
  }
  return contract.approve(target, p);
}

// 查询用户余额
async function getUserBalance(coinAddress) {
  let balance;
  if (coinAddress === '0x0000000000000000000000000000000000000000') {
    balance = await provider.getBalance(account);
  } else {
    const contract = new ethers.Contract(coinAddress, coinAbi, jsonRpcProvider);
    balance = await contract.balanceOf(account);
  }
  return ethers.utils.formatEther(balance);
}

// 购买nft
function buyToken(nftAddress, tokenId) {
  return exchangeContract.buyToken(nftAddress, tokenId);
}

// 下架
function revoke(nftAddress, tokenId) {
  return exchangeContract.cancelSellToken(nftAddress, tokenId);
}

// function revokeV1(nftAddress, tokenId) {
//   return exchangeContractV1.cancelSellToken(nftAddress, tokenId);
// }

// 转移token
function safeTransferFrom(to, nftAddress, tokenId) {
  const nftContract = new ethers.Contract(nftAddress, nftAbi, provider.getSigner());
  return nftContract['safeTransferFrom(address,address,uint256)'](account, to, tokenId);
}

// 获取nft总量
async function totalSupply(nftAddress) {
  const contract = new ethers.Contract(nftAddress, nftAbi, jsonRpcProvider);
  const res = await contract.totalSupply();
  return ethers.utils.formatUnits(res, 0);
}

const auction = {
  // 获取查询已上架NFT列表
  async getAsks(nftAddress) {
    await getReadOnlyAuctionContract();
    const res = await readOnlyAuctionContract.asks(nftAddress);
    return res.map((item) => ethers.utils.formatUnits(item.tokenId, 0));
  },

  // 获取NFT详情
  async getArtworkData(nftAddress, tokenId) {
    await getReadOnlyAuctionContract();
    return readOnlyAuctionContract.getAskData(nftAddress, tokenId);
  },

  // 查询用户未拍卖的NFT列表
  async getUserPendingArtwork(nftAddress) {
    const nftContract = new ethers.Contract(nftAddress, nftAbi, provider.getSigner());
    const res = await nftContract.tokensOfOwner(account);
    return res.map((item) => ethers.utils.formatUnits(item, 0));
  },

  // 查询用户拍卖中的NFT列表
  async getUserReviewedArtwork(nftAddress) {
    const res = await auctionContract.getAsksByUser(account, nftAddress);
    return res.map((item) => ethers.utils.formatUnits(item.tokenId, 0));
  },

  // 查询用户参与拍卖的NFT列表
  async getUserBiddingArtwork(nftAddress) {
    const res = await auctionContract.getBidsByUser(account, nftAddress);
    return res.map((item) => ethers.utils.formatUnits(item.tokenId, 0));
  },

  // 查询NFT拥有者
  async getNftOwner(nftAddress, tokenId) {
    const readOnlyNftContract = new ethers.Contract(nftAddress, nftAbi, jsonRpcProvider);
    const res = await readOnlyNftContract.ownerOf(tokenId);
    return res.toLowerCase();
  },

  // 查询NFT授权
  async getApproved(nftAddress, tokenId) {
    const readOnlyNftContract = new ethers.Contract(nftAddress, nftAbi, jsonRpcProvider);
    const res = await readOnlyNftContract.getApproved(tokenId);
    return res.toLowerCase();
  },

  // NFT授权
  approve(nftAddress, tokenId) {
    const nftContract = new ethers.Contract(nftAddress, nftAbi, provider.getSigner());
    return nftContract.approve(auctionAddress, tokenId);
  },

  // 获取支持货币
  async getCoins() {
    await getReadOnlyAuctionContract();
    return readOnlyAuctionContract.getCoins();
  },

  // 上架拍卖
  transfer(data) {
    const price = ethers.utils.parseEther(`${data.price}`);
    const target = data.nftAddress.toLowerCase() === oldNftAddress.toLowerCase() ? 'readyToSellToken' : 'readyToSellTokenV2';
    console.log(data);
    return auctionContract[target](data.nftAddress, data.coin, data.tokenId, price, data.start, data.end);
  },

  // 查询拍卖品出价
  async getBids(nftAddress, tokenId) {
    await getReadOnlyAuctionContract();
    const res = await readOnlyAuctionContract.getBids(nftAddress, tokenId);
    return res.map((item) => ({
      address: item.bidder,
      price: ethers.utils.formatUnits(item.price.toString()),
      coin: item.coin,
    })).reverse();
  },

  // 获取用户出价
  async getUserBids(nftAddress, tokenId) {
    const res = await auctionContract.getBidData(nftAddress, tokenId, account);
    return ethers.utils.formatUnits(res.price.toString());
  },

  // 用户出价授权
  coinApprove(price, coin) {
    const p = ethers.utils.parseEther(price.toString());
    const contract = new ethers.Contract(coin, coinAbi, provider.getSigner());
    return contract.approve(auctionAddress, p);
  },

  // 查询用户授权
  getCoinApprove(coin) {
    const contract = new ethers.Contract(coin, coinAbi, provider.getSigner());
    return contract.allowance(account, auctionAddress);
  },

  // 用户出价
  bidToken(nftAddress, tokenId, price, isBnb = false) {
    const p = ethers.utils.parseEther(price.toString());
    const value = isBnb ? p : '0';
    return auctionContract.bidToken(nftAddress, tokenId, p, {
      from: account,
      value,
    });
  },

  // 更新出价
  updateBidPrice(nftAddress, tokenId, price, oldPrice, isBnb = false) {
    const priceBigNumber = ethers.utils.parseEther(price.toString());
    const oldPriceBigNumber = ethers.utils.parseEther(oldPrice.toString());
    const p = ethers.BigNumber.from(priceBigNumber).sub(oldPriceBigNumber);
    const value = isBnb ? p : '0';
    return auctionContract.updateBidPrice(nftAddress, tokenId, priceBigNumber, {
      from: account,
      value,
    });
  },

  // 取消出价
  cancelBidToken(nftAddress, tokenId) {
    return auctionContract.cancelBidToken(nftAddress, tokenId);
  },

  // 拍卖确认
  async sellTokenTo(nftAddress, tokenId) {
    const res = await this.getBids(nftAddress, tokenId);
    console.log('%c res', 'color: #0f0', res);
    return auctionContract.sellTokenTo(nftAddress, tokenId, res[0].address);
  },

  // 查询用户余额
  async getUserBalance(coin) {
    let balance;
    if (coin === '0x0000000000000000000000000000000000000000') {
      balance = await provider.getBalance(account);
    } else {
      const contract = new ethers.Contract(coin, coinAbi, jsonRpcProvider);
      balance = await contract.balanceOf(account);
    }
    return ethers.utils.formatEther(balance);
  },

  // 下架拍卖品
  cancelSellToken(nftAddress, tokenId) {
    return auctionContract.cancelSellToken(nftAddress, tokenId);
  },
};

export default {
  exchangeAddress,
  oldNftAddress,
  factoryAddress,
  getConnectionActionRecord,
  auctionAddress,
  getAccount,
  connectWallet,
  disconnectWallet,
  getAsks,
  getUserPendingArtwork,
  getNftOwner,
  getApproved,
  approve,
  getCoinApprove,
  getCoins,
  getMintFee,
  getUserBalance,
  deployAndMint,
  mint,
  transfer,
  coinApprove,
  buyToken,
  getMintFeeAddress,
  revoke,
  getAsksByUser,
  safeTransferFrom,
  totalSupply,
  getBaseUrl,
  setBaseUrl,
  auction,
};
