import { createI18n } from 'vue-i18n';
import chinese from '@/utils/locales/zh';
import traditional from '@/utils/locales/tc';
import english from '@/utils/locales/en';

const i18n = createI18n({
  locale: localStorage.getItem('language') || 'en',
  messages: {
    en: english,
    tc: traditional,
    zh: chinese,
  },
});
export default i18n;
