export default {
  address: {
    main: '0xb592e6b1E12c72Fd95851bA40814A97D448F3615',
    test: '0xfa02bde6657105cfc4556eb057ca34127ec28214',
  },
  abi: [{
    inputs: [{
      internalType: 'address',
      name: '_adminAccount',
      type: 'address',
    }, {
      internalType: 'address',
      name: '_defaultCoin',
      type: 'address',
    }, {
      internalType: 'address payable',
      name: '_defaultFeeAccount',
      type: 'address',
    }, { internalType: 'uint256', name: '_defaultFeeAmount', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'constructor',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }, {
      indexed: true, internalType: 'string', name: 'name', type: 'string',
    }, {
      indexed: true, internalType: 'address', name: 'nft', type: 'address',
    }, {
      indexed: false, internalType: 'string', name: 'symbol', type: 'string',
    }, {
      indexed: false, internalType: 'address', name: 'coin', type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'feeAcount', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'feeAmout', type: 'uint256',
    }],
    name: 'Create',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
    }, {
      indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32',
    }, {
      indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32',
    }],
    name: 'RoleAdminChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
    }, {
      indexed: true, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }],
    name: 'RoleGranted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
    }, {
      indexed: true, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }],
    name: 'RoleRevoked',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'SetAccount',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'SetAdmin',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'amount', type: 'uint256',
    }],
    name: 'SetAmount',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'coin', type: 'address',
    }],
    name: 'SetCoin',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }, {
      indexed: true, internalType: 'bool', name: 'open', type: 'bool',
    }],
    name: 'SetOpen',
    type: 'event',
  }, {
    inputs: [],
    name: 'ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'adminAccount',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_nftAddress', type: 'address' }],
    name: 'checkNFT',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'defaultCoin',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'defaultFeeAccount',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'defaultFeeAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'string', name: 'name', type: 'string' }, {
      internalType: 'string',
      name: 'symbol',
      type: 'string',
    }, {
      internalType: 'address payable',
      name: 'creator',
      type: 'address',
    }, { internalType: 'string', name: '_tokenUri', type: 'string' }, {
      internalType: 'uint256',
      name: '_tokenRoyalties',
      type: 'uint256',
    }],
    name: 'deployAndMintNFTDefault',
    outputs: [{ internalType: 'address', name: 'pair', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'string', name: 'name', type: 'string' }, {
      internalType: 'string',
      name: 'symbol',
      type: 'string',
    }, {
      internalType: 'address',
      name: 'coin',
      type: 'address',
    }, {
      internalType: 'address payable',
      name: 'feeAccount',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: 'feeAmount',
      type: 'uint256',
    }, {
      internalType: 'address payable',
      name: 'creator',
      type: 'address',
    }, { internalType: 'string', name: '_tokenUri', type: 'string' }, {
      internalType: 'uint256',
      name: '_tokenRoyalties',
      type: 'uint256',
    }],
    name: 'deployNFTAdmin',
    outputs: [{ internalType: 'address', name: 'pair', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'string', name: 'name', type: 'string' }, {
      internalType: 'string',
      name: 'symbol',
      type: 'string',
    }],
    name: 'deployNFTDefault',
    outputs: [{ internalType: 'address', name: 'pair', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'getNFTs',
    outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'uint256',
      name: 'index',
      type: 'uint256',
    }],
    name: 'getRoleMember',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleMemberCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'address',
      name: 'account',
      type: 'address',
    }],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'address',
      name: 'account',
      type: 'address',
    }],
    name: 'hasRole',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'nfts',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'open',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'address',
      name: 'account',
      type: 'address',
    }],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'address',
      name: 'account',
      type: 'address',
    }],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
    name: 'setAdmin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_address', type: 'address' }],
    name: 'setCoin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address payable', name: '_address', type: 'address' }],
    name: 'setFeeAccount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_amount', type: 'uint256' }],
    name: 'setFeeAmount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bool', name: '_open', type: 'bool' }],
    name: 'setOpen',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, { stateMutability: 'payable', type: 'receive' }],
};
