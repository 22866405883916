class Decimal {
  // eslint-disable-next-line class-methods-use-this
  digitNum(strNum1) {
    let dig = 0;
    if (strNum1.indexOf('.') >= 0) {
      dig = strNum1.split('.')[1].length;
    }
    return dig;
  }

  numEnlarge(num1, num2) {
    const strNum1 = num1.toString();
    const strNum2 = num2.toString();
    const r1 = this.digitNum(strNum1);
    const r2 = this.digitNum(strNum2);
    if (r1 === 0 && r2 === 0) {
      const aa = parseInt(strNum1, 10);
      const bb = parseInt(strNum2, 10);
      return [aa, bb, 1];
    }
    let intNum1 = parseInt(strNum1.replace('.', ''), 10);
    let intNum2 = parseInt(strNum2.replace('.', ''), 10);
    if (r1 > r2) {
      intNum2 *= 10 ** (r1 - r2);
    } else if (r1 < r2) {
      intNum1 *= 10 ** (r2 - r1);
    }
    return [intNum1, intNum2, 10 ** Math.max(r1, r2)];
  }

  // 加
  add(a, b) {
    const nums = this.numEnlarge(a, b);
    return (nums[0] + nums[1]) / nums[2];
  }

  // 减
  subtract(a, b) {
    const nums = this.numEnlarge(a, b);
    return (nums[0] - nums[1]) / nums[2];
  }

  // 乘
  multiply(a, b) {
    b = b || 1;
    const nums = this.numEnlarge(a, b);
    return (nums[0] * nums[1]) / (nums[2] * nums[2]);
  }

  // 除
  divide(a, b) {
    const nums = this.numEnlarge(a, b);
    return nums[0] / nums[1];
  }
}

export default new Decimal();
