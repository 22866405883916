import emitter from 'tiny-emitter/instance';

export default {
  $on: (...args) => {
    console.log(`on:${JSON.stringify(args)}`);
    emitter.on(...args);
  },
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};
