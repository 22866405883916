const env = 'test';

const baseUrl = {
  main: '',
  test: process.env.VUE_APP_BASE_URL,
};

const networkUrl = {
  main: 'https://bsc-dataseed4.ninicoin.io/',
  test: 'https://bsc-testnet.web3api.com/v1/ZFFPJB1PI543VWR5PH85WXDQWIVA1E97C3',
};

const chainId = {
  main: 56,
  test: 97,
};

const chainIdHex = {
  main: '0x38',
  test: '0x61',
};

const defaultCollectionIds = {
  main: 16,
  test: 25,
};
// TODO 改为通过API获取
const collectionDetailAddition = {
  main: {
    // 8: {
    //   additionName: 'Earth and Dream',
    //   allowApprove: true,
    //   // limitBuy: true,
    //   // limitFromCollectionId: 4, // 自少要拥有1个加密熊(新旧都可以)
    // },
    // 107: {
    //   additionName: '721OkayBear',
    //   allowApprove: true,
    //   // limitBuy: true,
    //   // limitFromCollectionId: 4, // 自少要拥有1个加密熊(新旧都可以)
    // },
  },
  test: {
    50: {
      additionName: 'Earth and Dream',
      allowApprove: true,
      // limitBuy: true,
      // limitFromCollectionId: 49, // 要购买46这个系列，自少要拥有1个47系列的nft
    },
  },
};

const networkInfo = {
  main: [{
    chainId: '0x38', // 56 16进制：0x38
    chainName: 'BSC Mainnet',
    rpcUrls: ['https://bsc-dataseed4.ninicoin.io/'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://bscscan.com/'],
  }],
  test: [{
    chainId: '0x61', // 97 16进制：0x61
    chainName: 'BSC Testnet',
    rpcUrls: ['https://bsc-testnet.web3api.com/v1/ZFFPJB1PI543VWR5PH85WXDQWIVA1E97C3'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
  }],
};

export default {
  env,
  baseUrl: baseUrl[env],
  networkUrl: networkUrl[env],
  chainId: chainId[env],
  chainIdHex: chainIdHex[env],
  networkInfo: networkInfo[env],
  defaultCollectionId: defaultCollectionIds[env],
  collectionDetailAddition: collectionDetailAddition[env],
};
