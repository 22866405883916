export default {
  name: 'PopupBox',
  props: {
    title: {
      type: String,
      default: 'Tips'
    },
    message: {
      type: String
    },
    isHtml: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    confirm: {
      type: Function
    },
    cancel: {
      type: Function
    }
  }
};