import { createStore } from 'vuex';

export default createStore({
  state: {
    displayAccount: null,
    username: null,
    myCode: null,
    token: null,
    Account: null,
    language: localStorage.getItem('language') || 'zh',
    admin: [
      '0x7ef4f062213148f5be71c3009c3aa88b462d3a83', // 维狄
      '0x3a24845cf2842b7367341a59a94acc6a104915e2', // 刺猬
    ],
    leader: [
      '0xde40b30511b1a371cdad793e75ed32de531905c5', // 维狄
      '0x15d35b0849d4437568a67cc53a0bb2406c61cdf9', // 刺猬
    ],
    mint: [
      '0x7ef4f062213148f5be71c3009c3aa88b462d3a83', // 维狄
      '0x3a24845cf2842b7367341a59a94acc6a104915e2', // 刺猬
    ],
  },
  mutations: {
    setDisplayAccount(state, data) {
      state.displayAccount = data ? `${data.slice(0, 4)}...${data.slice(-4)}` : null;
      state.Account = data.toLowerCase() || null;
    },
    setMyCode(state, data) {
      state.myCode = data;
    },
    setUsername(state, data) {
      state.username = data;
    },
    setToken(state, data) {
      state.token = data;
    },
    setAddress(state, data) {
      if (data.admin) {
        state.admin.push(...data.admin);
      }
      if (data.groupLeader) {
        state.leader.push(...data.groupLeader);
      }
      if (data.miner) {
        state.mint.push(...data.miner);
      }
    },
    setLanguage(state, language) {
      state.language = language;
      localStorage.setItem('language', language);
    },
  },
  getters: {},
  actions: {
    setLanguage(context, value) {
      context.commit('language', value);
      localStorage.setItem('language', value);
    },
  },
  modules: {},
});
