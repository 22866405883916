export default {
  name: 'WalletBox',
  props: {
    confirm: {
      type: Function
    },
    cancel: {
      type: Function
    }
  },

  data() {
    return {
      connecting: false,
      close: null
    };
  },

  methods: {
    connectWallet(type) {
      this.connecting = true;
      const p = this.$ethers.connectWallet(type);
      const p1 = new Promise((resolve, reject) => {
        this.close = reject;
      });
      Promise.race([p, p1]).then(() => this.confirm()).catch(error => {
        this.cancel();
        console.log('%c 连接钱包出错：', 'color: #f00', error);
      });
    },

    closeBox() {
      if (this.connecting) {
        this.close('用户手动取消');
      } else {
        this.cancel();
      }
    }

  }
};