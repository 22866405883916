export default {
  menu: {
    searchText: '搜索項目、系列和帳戶',
    auction: '拍賣',
    collection: '系列',
    referral: '推薦',
    language: '語言',
    mint: '鑄造',
    connectWallet: '連接錢包',
    infoBox: {
      email: '帳號',
      referral: '推薦',
      allReferral: '所有推薦',
    },
  },
  home: {
    buyNow: '即刻購買',
    popularSeries: '熱門系列',
    topCollections: '熱門<span>收藏</span>',
    hotIp: '熱門 IP',
    deals: '交易',
    downloadTitle: `以最佳方式<br>
    管理您的 NFT<br>`,
    hotPrice: 'USDT',
    hotBuy: '購買',
    seeMore: '查看更多',
    viewAll: '查看全部',
  },
  list: {
    items: '項',
    owners: '擁有者',
    floorPrice: '底價',
    volumeTraded: '成交量',
    allNFTs: '全部NFT',
    myNFTs: '我的NFT',
    search: '搜索',
    searchText: '搜索',
    buyNow: '購買',
    Transfer: '轉移',
  },
  walletBox: {
    title: '連結到您的錢包',
  },
  mint: {
    upload: '上傳',
    artwork: '藝術品',
    information: '藝術品信息',
    phTitle: '輸入作品名稱',
    phName: '輸入作品名稱',
    collection: '創建一個系列',
    phCollection: '選擇系列',
    phLink: '個人網站、Instagram、Twitter等',
    phDescription: '輸入簡介',
    artworkType: '藝術品類型',
    artworkName: '藝術品名字',
    artisName: '藝名',
    collectionTitle: '系列',
    link: '社交媒體/投資組合鏈接',
    introduction: '介紹',
    mintTitle: '上傳',
    uploadTips: '最大 50MB ,PNG,JPEG,MP3,MP4',
    uploadBut: '瀏覽文件',
    checkboxText: '我聲明這是一件原創藝術品。',
    mintBut: '鑄造',
  },
  referral: {
    title: '推薦',
    tips: '與您的朋友分享 NFT 並一起賺錢！',
    key: '推薦 ID',
    sold: '已售商品',
    listTitle: '推薦名單',
    searchEMail: '郵箱',
    Search: '搜索',
    headerEmail: '郵箱',
    headerWallet: '錢包',
    headerUpdated: '更新',
    mEmail: '郵箱',
    mWallet: '錢包',
    mUpdated: '更新',
    soldTitle: '出售清單',
    headerItem: '名稱',
    headerPrice: '價格',
    headerFrom: '從',
    headerTo: '至',
    headerToken: 'Token ID',
    mItem: '名稱',
    mPrice: '價格',
    mFrom: '從',
    mTo: '至',
    mToken: 'Token ID',

  },
  createCollection: {
    editTitle: '編輯系列',
    createTitle: '創建一個系列',
    titleBut: '後退',
    labelLogo: 'Logo圖片',
    labelLogoTips: '他的圖像也將用於導航。推薦 300x300',
    labelBanner: '橫幅圖片',
    labelBannerTips: '此圖像將出現在您的收藏頁面的頂部。',
    labelName: '姓名',
    phlabelName: '示例：海洋寶藏',
    labelEarnings: '鑄造者收益',
    phlabelEarnings: '10％',
    labelEarningsTips: '通過 Petaverse 交易的該系列下的每個 NFT 都會有一定比例的奖励給指定的錢包地址。',
    labelRecipient: '收益錢包地址',
    phlabelRecipient: '接收收益的錢包地址',
    labelRecipientTips: '指定的錢包地址，用於從該系列的每次銷售中收取版稅。',
    labelDescription: '描述',
    confirm: '確認',
    create: '創造',
  },
  soldList: {
    search: '搜索',
    reset: '重置',
    soldList: '出售清單',
    exportToExcel: '導出到 Excel',
  },
  userCenter: {
    welcome: '歡迎回來',
    home: '首頁',
    myNfts: '我的NFTs',
    myCollections: '我的系列',
    referral: '推薦',
  },
  actionList: {
    auction: '拍賣',
    myArtWork: '我的藝術品',
    Pending: '待辦的',
    Reviewed: '已審核',
    Bidding: '投標',
    phCollection: '選擇收藏',
    Search: '搜索',
    phSearch: '選擇收藏',
    OnAuction: '拍賣中',
    over: '拍賣結束',
    CurrentBid: '當前出價',
    EndsIn: '結束於',
    AuctionStartIn: '拍賣開始於',
    NothingToShow: '數據為空',
  },
  detail: {
    buyNow: '立即購買',
    approve: '批准',
    transfer: '交易',
    revoke: '撤銷',
    send: '轉贈',
    nftContract: 'NFT 合約:',
    creatorAddress: '鑄造者地址:',
    transactionHistory: '交易記錄',
    event: '事件',
    price: '價格',
    from: '從',
    to: '至',
    date: '日期',
    noHistoryYet: '還沒有歷史',
    vevent: '事件',
    vprice: '價格',
    vfrom: '從',
    vto: '至',
    vdate: '日期',
    vnoHistoryYet: '還沒有歷史',
    transferTitle: '交易',
    confirm: '確認',
    buyNow2: '立即購買',
    tips: '如果您沒有邀請碼，請直接點擊下方的確認按鈕。',
    confirm2: '確認',
    send2: '轉贈',
    confirm3: '確認',

  },
  bottomBar: {
    title: '加入 Petaverse 社區',
  },
  collectionsList: {
    myCollections: '我的系列',
    createCollection: '創建一個系列',
    edit: '編輯',
  },
  collectionList: {
    items: '項',
    owners: '擁有者',
    floorPrice: '底價',
    volumeTraded: '成交量',
    search: '搜索',
    buyNow: '立即購買',
    transfer: '交易',
  },
  auctionDetail: {
    backBut: '後退',
    artist: '藝術家:',
    over: '拍賣結束',
    startIn: '拍賣開始於',
    currentBid: '當前出價',
    yourBig: '您的出價',
    connectWallet: '連接錢包',
    sell: '出售',
    approve: '批准',
    transfer: '交易',
    bidding: '投標',
    cancel: '取消',
    changePrice: '更改價格',
    cancelSell: '取消出售',
    nftContractID: 'NFT合約ID',
    tokenID: 'TokenID',
    creatorAddress: '鑄造者地址',
    owner: '擁有者地址',
    address: '地址',
    price: '價格',
    yourBid2: '您的出價',
    confirm: '確認',
    transferInfo: '交易信息',
    confirm2: '確認',
    currency: '貨幣',
    phCurrency: '選擇貨幣',
    boxPrice: '價格',
    phPrice: '請輸入價格',
    start: '開始',
    phStart: '請選擇開始時間',
    end: '擇結',
    phEnd: '請選擇結束時間',
  },
  countdownBox: {
    endsIn: '拍賣結束於',
    days: '天',
    hours: '時',
    mins: '分',
    secs: '秒',
  },
};
