import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/mint',
    name: 'mint',
    component: () => import(/* webpackChunkName: "mint" */ '@/views/MintView.vue'),
  },
  {
    path: '/list',
    name: 'list',
    component: () => import(/* webpackChunkName: "list" */ '@/views/ListView.vue'),
  },
  {
    path: '/collection-list/:id',
    name: 'collectionList',
    props: true,
    component: () => import(/* webpackChunkName: "collectionList" */ '@/views/CollectionList.vue'),
  },
  {
    path: '/detail/:id',
    name: 'detail',
    props: true,
    component: () => import(/* webpackChunkName: "detail" */ '@/views/DetailView.vue'),
  },
  {
    path: '/referred-list',
    name: 'referredList',
    component: () => import(/* webpackChunkName: "referredList" */ '@/views/ReferredList.vue'),
  },
  {
    path: '/sold-list',
    name: 'soldList',
    component: () => import(/* webpackChunkName: "soldList" */ '@/views/SoldList.vue'),
  },
  {
    path: '/all-referral',
    name: 'allReferral',
    component: () => import(/* webpackChunkName: "allReferral" */ '@/views/AllReferral.vue'),
  },
  {
    path: '/user-center',
    name: 'userCenter',
    redirect: '/user-center/collections-list',
    component: () => import(/* webpackChunkName: "userCenter" */ '@/views/UserCenter.vue'),
    children: [
      {
        path: 'collections-list',
        name: 'collectionsList',
        component: () => import(/* webpackChunkName: "collectionsList" */ '@/views/CollectionsList.vue'),
      },
      {
        path: 'create-collection',
        name: 'createCollection',
        component: () => import(/* webpackChunkName: "createCollection" */ '@/views/CreateCollection.vue'),
      },
      {
        path: 'mynft-list',
        name: 'mynftList',
        component: () => import(/* webpackChunkName: "MyNftListView" */ '@/views/MyNftListView.vue'),
      },
    ],
  },
  {
    path: '/auction',
    name: 'Auction',
    component: () => import(/* webpackChunkName: "Auction" */ '@/views/AuctionList.vue'),
  },
  {
    path: '/auction-detail/:nftId',
    name: 'AuctionDetail',
    component: () => import(/* webpackChunkName: "AuctionDetail" */ '@/views/AuctionDetail.vue'),
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
