export default {
  address: {
    main: '0xF3E84B3ef685ADdEf112b1BCe5c057Aa83F82f03',
    test: '0x38b7f09D6A005840E8afd0883C90352727fe3120',
  },
  abi: [{
    inputs: [{
      internalType: 'address payable',
      name: '_feeAddr',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: '_feePercent',
      type: 'uint256',
    }, {
      internalType: 'address',
      name: '_iCoin',
      type: 'address',
    }, { internalType: 'address', name: '_iNFT', type: 'address' }],
    stateMutability: 'nonpayable',
    type: 'constructor',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'nftAddress',
      type: 'address',
    }, {
      indexed: false,
      internalType: 'address',
      name: 'coinAddress',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'seller',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'uint256',
      name: 'tokenId',
      type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'price', type: 'uint256',
    }],
    name: 'Ask',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'bidder',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'nftAddress',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'uint256',
      name: 'tokenId',
      type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'price', type: 'uint256',
    }],
    name: 'Bid',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'bidder',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'nftAddress',
      type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }],
    name: 'CancelBidToken',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'nftAddress',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'seller',
      type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }],
    name: 'CancelSellToken',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'previousOwner',
      type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'FeeAddressTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'previousOwner',
      type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'address',
      name: 'account',
      type: 'address',
    }],
    name: 'Paused',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'nftAddress',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'uint256',
      name: 'tokenId',
      type: 'uint256',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'creator',
      type: 'address',
    }, {
      indexed: false, internalType: 'address', name: 'owner', type: 'address',
    }],
    name: 'SetCreator',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'seller',
      type: 'address',
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'oldFeePercent',
      type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'newFeePercent', type: 'uint256',
    }],
    name: 'SetFeePercent',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'nftAddress',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'uint256',
      name: 'tokenId',
      type: 'uint256',
    }, {
      indexed: true,
      internalType: 'uint256',
      name: 'royalties',
      type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'owner', type: 'address',
    }],
    name: 'SetRoyalties',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'address',
      name: 'nftAddress',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'seller',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'buyer',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'uint256',
      name: 'tokenId',
      type: 'uint256',
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'price',
      type: 'uint256',
    }, {
      indexed: false,
      internalType: 'uint256',
      name: 'fee',
      type: 'uint256',
    }, {
      indexed: false, internalType: 'address', name: 'coin', type: 'address',
    }],
    name: 'Trade',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false,
      internalType: 'address',
      name: 'account',
      type: 'address',
    }],
    name: 'Unpaused',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'user',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'coin',
      type: 'address',
    }, {
      indexed: true, internalType: 'bool', name: 'support', type: 'bool',
    }],
    name: 'eveSupportCoin',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true,
      internalType: 'address',
      name: 'user',
      type: 'address',
    }, {
      indexed: true,
      internalType: 'address',
      name: 'nft',
      type: 'address',
    }, {
      indexed: true, internalType: 'bool', name: 'support', type: 'bool',
    }],
    name: 'eveSupportNft',
    type: 'event',
  }, {
    inputs: [{
      internalType: 'address',
      name: '',
      type: 'address',
    }, { internalType: 'uint256', name: '', type: 'uint256' }],
    name: '_nftAskEntry',
    outputs: [{
      internalType: 'address',
      name: 'tokenAddress',
      type: 'address',
    }, {
      internalType: 'address payable',
      name: 'owner',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: 'tokenId',
      type: 'uint256',
    }, {
      internalType: 'uint256',
      name: 'price',
      type: 'uint256',
    }, {
      internalType: 'address',
      name: 'coin',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: 'start',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'end', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '',
      type: 'address',
    }, { internalType: 'uint256', name: '', type: 'uint256' }],
    name: '_nftBidEntry',
    outputs: [{
      internalType: 'address',
      name: 'tokenAddress',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: 'tokenId',
      type: 'uint256',
    }, {
      internalType: 'address',
      name: 'bidder',
      type: 'address',
    }, {
      internalType: 'address',
      name: 'coin',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: 'price',
      type: 'uint256',
    }, { internalType: 'uint256', name: 'time', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '',
      type: 'address',
    }, { internalType: 'uint256', name: '', type: 'uint256' }],
    name: '_tokenCreators',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '',
      type: 'address',
    }, { internalType: 'uint256', name: '', type: 'uint256' }],
    name: '_tokenRoyalties',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_nftAddress', type: 'address' }],
    name: 'asks',
    outputs: [{
      components: [{
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      }, {
        internalType: 'address payable',
        name: 'owner',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'end', type: 'uint256' }],
      internalType: 'struct OpenGateMarketV3.AskEntry[]',
      name: '',
      type: 'tuple[]',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    }, { internalType: 'uint256', name: '_price', type: 'uint256' }],
    name: 'bidToken',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_nftAddress', type: 'address' }],
    name: 'bids',
    outputs: [{
      components: [{
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, {
        internalType: 'address',
        name: 'bidder',
        type: 'address',
      }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'time', type: 'uint256' }],
      internalType: 'struct OpenGateMarketV3.BidEntry[]',
      name: '',
      type: 'tuple[]',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'cancelBidToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'cancelSellToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddr',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'creator',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'feeAddr',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'feePercent',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'getAskData',
    outputs: [{
      components: [{
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      }, {
        internalType: 'address payable',
        name: 'owner',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'end', type: 'uint256' }],
      internalType: 'struct OpenGateMarketV3.AskEntry',
      name: '',
      type: 'tuple',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_nftAddress', type: 'address' }],
    name: 'getAskLength',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: 'user',
      type: 'address',
    }, { internalType: 'address', name: '_nftAddress', type: 'address' }],
    name: 'getAsksByUser',
    outputs: [{
      components: [{
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      }, {
        internalType: 'address payable',
        name: 'owner',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'end', type: 'uint256' }],
      internalType: 'struct OpenGateMarketV3.AskEntry[]',
      name: '',
      type: 'tuple[]',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    }, { internalType: 'address', name: 'bidder', type: 'address' }],
    name: 'getBidData',
    outputs: [{
      components: [{
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, {
        internalType: 'address',
        name: 'bidder',
        type: 'address',
      }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'time', type: 'uint256' }],
      internalType: 'struct OpenGateMarketV3.BidEntry',
      name: '',
      type: 'tuple',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'getBids',
    outputs: [{
      components: [{
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, {
        internalType: 'address',
        name: 'bidder',
        type: 'address',
      }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'time', type: 'uint256' }],
      internalType: 'struct OpenGateMarketV3.BidEntry[]',
      name: '',
      type: 'tuple[]',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: 'user',
      type: 'address',
    }, { internalType: 'address', name: '_nftAddress', type: 'address' }],
    name: 'getBidsByUser',
    outputs: [{
      components: [{
        internalType: 'address',
        name: 'tokenAddress',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'tokenId',
        type: 'uint256',
      }, {
        internalType: 'address',
        name: 'bidder',
        type: 'address',
      }, {
        internalType: 'address',
        name: 'coin',
        type: 'address',
      }, {
        internalType: 'uint256',
        name: 'price',
        type: 'uint256',
      }, { internalType: 'uint256', name: 'time', type: 'uint256' }],
      internalType: 'struct OpenGateMarketV3.BidEntry[]',
      name: '',
      type: 'tuple[]',
    }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'getCoins',
    outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'getNfts',
    outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'nftH',
    outputs: [{ internalType: 'contract IERC721Helper', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '',
      type: 'address',
    }, { internalType: 'address', name: '', type: 'address' }, {
      internalType: 'uint256',
      name: '',
      type: 'uint256',
    }, { internalType: 'bytes', name: '', type: 'bytes' }],
    name: 'onERC721Received',
    outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'openForOwner',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'openNFT',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'quoteErc20',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'address',
      name: '_coinAddress',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    }, {
      internalType: 'uint256',
      name: '_price',
      type: 'uint256',
    }, {
      internalType: 'uint256',
      name: '_start',
      type: 'uint256',
    }, { internalType: 'uint256', name: '_end', type: 'uint256' }],
    name: 'readyToSellToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'address',
      name: '_coinAddress',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    }, {
      internalType: 'uint256',
      name: '_price',
      type: 'uint256',
    }, {
      internalType: 'uint256',
      name: '_start',
      type: 'uint256',
    }, {
      internalType: 'uint256',
      name: '_end',
      type: 'uint256',
    }, {
      internalType: 'address payable',
      name: '_creator',
      type: 'address',
    }, { internalType: 'uint256', name: '_royalties', type: 'uint256' }],
    name: 'readyToSellTokenByOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'address',
      name: '_coinAddress',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    }, {
      internalType: 'uint256',
      name: '_price',
      type: 'uint256',
    }, {
      internalType: 'uint256',
      name: '_start',
      type: 'uint256',
    }, { internalType: 'uint256', name: '_end', type: 'uint256' }],
    name: 'readyToSellTokenV2',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddr',
      type: 'address',
    }, { internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'royalties',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'royaltiesBase',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'royaltiesTop',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    }, { internalType: 'address', name: '_to', type: 'address' }],
    name: 'sellTokenTo',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_feePercent', type: 'uint256' }],
    name: 'setFeePercent',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bool', name: '_open', type: 'bool' }],
    name: 'setOpenForOwner',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_royalties', type: 'uint256' }],
    name: 'setRoyaltiesTop',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address payable', name: '_feeAddr', type: 'address' }],
    name: 'transferFeeAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nftAddress',
      type: 'address',
    }, {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    }, { internalType: 'uint256', name: '_price', type: 'uint256' }],
    name: 'updateBidPrice',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_coin',
      type: 'address',
    }, { internalType: 'bool', name: '_isSupport', type: 'bool' }],
    name: 'updateSupportCoin',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'address',
      name: '_nft',
      type: 'address',
    }, { internalType: 'bool', name: '_isSupport', type: 'bool' }],
    name: 'updateSupportNFT',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }],
};
