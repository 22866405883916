import { createApp } from 'vue';
import utils from '@/utils/ethers';
import WalletBoxComponent from './WalletBox.vue';

function WalletBox() {
  return new Promise((resolve, reject) => {
    let walletBoxInstance = null;
    const mountNode = document.createElement('div');
    const unmount = () => {
      walletBoxInstance.unmount();
      document.body.removeChild(mountNode);
    };
    walletBoxInstance = createApp(WalletBoxComponent, {
      confirm() {
        unmount();
        resolve();
      },
      cancel() {
        unmount();
        reject();
      },
    });
    document.body.appendChild(mountNode);
    walletBoxInstance.config.globalProperties.$ethers = utils;
    walletBoxInstance.mount(mountNode);
  });
}

export default function install(app) {
  // eslint-disable-next-line
  app.config.globalProperties.$walletBox = WalletBox;
}
