export default {
  menu: {
    searchText: 'Search items,collections,and accounts',
    auction: 'Auction',
    collection: 'Collection',
    referral: 'Referral',
    language: 'Language',
    mint: 'Mint',
    connectWallet: 'Connect Wallet',
    infoBox: {
      email: 'Email',
      referral: 'Referral',
      allReferral: 'ALL Referral',
    },
  },
  home: {
    buyNow: 'Buy Now',
    popularSeries: 'Popular Series',
    topCollections: 'Top <span>collections</span>',
    hotIp: 'Hot IP',
    deals: 'Deals',
    downloadTitle: `Manage your<br>
    NFTs in the best<br>
    possible way.`,
    hotPrice: 'USDT',
    hotBuy: 'Buy now',
    seeMore: 'See More',
    viewAll: 'View all',
  },
  list: {
    items: 'Items',
    owners: 'Owners',
    floorPrice: 'Floor Price',
    volumeTraded: 'Volume Traded',
    allNFTs: 'All NFTs',
    myNFTs: 'My NFTs',
    search: 'Search',
    searchText: 'Search',
    buyNow: 'Buy now',
    Transfer: 'Transfer',
  },
  walletBox: {
    title: 'Connect to a wallet',
  },
  mint: {
    upload: 'Upload',
    artwork: 'Artwork',
    information: 'Artwork information',
    phTitle: 'Enter the artwork name',
    phName: 'Enter the artwork name',
    collection: 'Create a collection',
    phCollection: 'Select the Collection',
    phLink: 'Personal website,Instagram,Twitter,etc.',
    phDescription: 'Enter the brief introduction',
    artworkType: 'Artwork Type',
    artworkName: 'Artwork Name',
    artisName: 'Artis Name',
    collectionTitle: 'Collection',
    link: 'Social Media/Portfolio link',
    introduction: 'Introduction',
    mintTitle: 'Upload',
    uploadTips: 'Max 50MB ,PNG,JPEG,MP3,MP4',
    uploadBut: 'Browse File',
    checkboxText: `I declare that this is an original artwork. I understand that no plagiarism is
    allowed, and that the artwork can be removed anytime if detected.`,
    mintBut: 'Mint',
  },
  referral: {
    title: 'Referral',
    tips: 'Share NFTs with your friends and earn together!',
    key: 'Referral ID',
    sold: 'Item Sold',
    listTitle: 'Referred List',
    searchEMail: 'Email',
    Search: 'Search',
    headerEmail: 'Email',
    headerWallet: 'Wallet',
    headerUpdated: 'Updated',
    mEmail: 'Email',
    mWallet: 'Wallet',
    mUpdated: 'Updated',
    soldTitle: 'Sold List',
    headerItem: 'Item name',
    headerPrice: 'Price',
    headerFrom: 'From',
    headerTo: 'To',
    headerToken: 'Token ID',
    mItem: 'Item name',
    mPrice: 'Price',
    mFrom: 'From',
    mTo: 'To',
    mToken: 'Token ID',
  },
  createCollection: {
    editTitle: 'Edit Collection',
    createTitle: 'Create a Collections',
    titleBut: 'Go Back',
    labelLogo: 'Logo Image',
    labelLogoTips: 'his image will also be used for navigation.300x300 recommended',
    labelBanner: 'Banner image',
    labelBannerTips: `This image will appear at the top of your collection page. Avoid
    including too much text in this banner image, as the dimensions change on different
    devices. 1430 x 370 recommended.`,
    labelName: 'Name',
    phlabelName: 'Example: Treasures of the sea',
    labelEarnings: 'Creator Earnings',
    phlabelEarnings: 'Up to 10%',
    labelEarningsTips: `Each NFT under this collection exchanged through Petaverse will have a
    percentage of sale given to nominated wallet address.`,
    labelRecipient: 'Fee Recipient',
    phlabelRecipient: 'Fee Recipient',
    labelRecipientTips: `The nominated Wallet address to recive royalties from each sale in this
    collection.`,
    labelDescription: 'Description',
    confirm: 'Confirm',
    create: 'Create',
    label: '',
  },
  soldList: {
    search: 'Search',
    reset: 'Reset',
    soldList: 'Sold List',
    exportToExcel: 'Export to Excel',
  },
  userCenter: {
    welcome: 'Welcome Back',
    home: 'Home',
    myNfts: 'My NFTs',
    myCollections: 'My Collections',
    referral: 'Referral',
  },
  actionList: {
    auction: 'Auction',
    myArtWork: 'My ArtWork',
    Pending: 'Pending',
    Reviewed: 'Reviewed',
    Bidding: 'Bidding',
    phCollection: 'Select the Collection',
    Search: 'Search',
    phSearch: 'Select the Collection',
    OnAuction: 'On Auction',
    over: 'Auction is over',
    CurrentBid: 'Current bid',
    EndsIn: 'Ends in',
    AuctionStartIn: 'Auction start in',
    NothingToShow: 'Nothing to show',
  },
  detail: {
    buyNow: 'Buy Now',
    approve: 'Approve',
    transfer: 'Transfer',
    revoke: 'Revoke',
    send: 'Send',
    nftContract: 'NFT Contract:',
    creatorAddress: "Creator's Address:",
    transactionHistory: 'Transaction History',
    event: 'Event',
    price: 'Price',
    from: 'From',
    to: 'To',
    date: 'Date',
    noHistoryYet: 'no history yet',
    vevent: 'Event',
    vprice: 'Price',
    vfrom: 'From',
    vto: 'To',
    vdate: 'Date',
    vnoHistoryYet: 'no history yet',
    transferTitle: 'Transfer',
    confirm: 'Confirm',
    buyNow2: 'Buy Now',
    tips: `If you do not have the invitation code please click the confirm button below
    directly.`,
    confirm2: 'Confirm',
    send2: 'Send',
    confirm3: 'Confirm',

  },
  bottomBar: {
    title: 'Join Petaverse community',
  },
  collectionsList: {
    myCollections: 'My Collections',
    createCollection: 'Create a collection',
    edit: 'Edit',
  },
  collectionList: {
    items: 'Items',
    owners: 'Owners',
    floorPrice: 'Floor Price',
    volumeTraded: 'Volume Traded',
    search: 'Search',
    buyNow: 'Buy now',
    transfer: 'Transfer',
  },
  auctionDetail: {
    backBut: 'Go back',
    artist: 'Artist:',
    over: 'Auction is over',
    startIn: 'Auction start in',
    currentBid: 'Current Bid',
    yourBig: 'Your Bid',
    connectWallet: 'Connect Wallet',
    sell: 'Sell',
    approve: 'Approve',
    transfer: 'Transfer',
    bidding: 'Bidding',
    cancel: 'Cancel',
    changePrice: 'Change Price',
    cancelSell: 'Cancel Sell',
    nftContractID: 'NFT Contract ID',
    tokenID: 'Token ID',
    creatorAddress: 'Creator Address',
    owner: 'Owner’s Address',
    address: 'Address',
    price: 'Price',
    yourBid2: 'Your Bid',
    confirm: 'Confirm',
    transferInfo: 'Transfer Info',
    confirm2: 'Confirm',
    currency: 'Currency',
    phCurrency: 'Select currency',
    boxPrice: 'Price',
    phPrice: 'Please enter price',
    start: 'Start',
    phStart: 'Please choose a start time',
    end: 'End',
    phEnd: 'Please choose a end time',
  },
  countdownBox: {
    endsIn: 'Auction ends in',
    days: 'Days',
    hours: 'Hours',
    mins: 'Mins',
    secs: 'Secs',
  },
};
