import { createApp } from 'vue';
import LoginComponent from './Login.vue';

function Login(address) {
  return new Promise((resolve, reject) => {
    let loginInstance = null;
    const mountNode = document.createElement('div');
    const unmount = () => {
      loginInstance.unmount();
      document.body.removeChild(mountNode);
    };
    loginInstance = createApp(LoginComponent, {
      address,
      confirm(myCode) {
        unmount();
        resolve(myCode);
      },
      cancel() {
        unmount();
        reject();
      },
    });
    document.body.appendChild(mountNode);
    loginInstance.mount(mountNode);
  });
}

export default Login;
