import config from '@/config';
export default {
  name: 'HomeView',

  data() {
    return {
      loading: true,
      artworkList: [],
      nftAddress: '',
      searchData: {
        ids: '',
        pageNum: 1,
        pageSize: 15,
        tokenAddress: '',
        sortBy: 'DESC',
        sortName: 'create_time',
        nftStatus: 10
      }
    };
  },

  async mounted() {
    const detail = await this.$api.getCollectionDetail(config.defaultCollectionId);

    if (detail == null || !detail.data || !detail.data.contract) {
      this.loading = false;
      return;
    }

    this.searchData.tokenAddress = detail.data.contract;

    if (detail.data != null && detail.data.contract) {
      const artworkData = await this.$ethers.getAsks(detail.data.contract);

      if (artworkData != null && artworkData.length) {
        this.searchData.ids = artworkData.map(item => item.tokenId).join(',');
        this.$api.getArtwork(this.searchData).then(res => {
          res.rows.forEach(item => {
            item.price = artworkData.find(i => item.tokenId === Number(i.tokenId)).price;
          });
          this.artworkList = res.rows;
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    }
  },

  methods: {
    toList(id = null) {
      if (id != null) {
        this.$router.push({
          name: 'list',
          query: {
            collection: id
          }
        });
      } else {
        this.$router.push('/list');
      }
    },

    toDetail(id) {
      this.$router.push(`/detail/${id}`);
    }

  }
};