export default {
  address: {
    main: '0x12fa5B806C18DF6b5D6Fc7a47a106fB54C3310FC',
    // test: '0xFfc75B7e06425Bcf5c7eB94045C76183807bb0FD',
    test: '0x6DC2f192Ab0F71986774A0B627C6BF58579649a5',
  },
  abi: [{
    inputs: [{
      internalType: 'string', name: 'name', type: 'string',
    }, { internalType: 'string', name: 'symbol', type: 'string' }, {
      internalType: 'address', name: '_feeERC20Address', type: 'address',
    }, {
      internalType: 'address payable', name: '_mintFeeAddr', type: 'address',
    }, { internalType: 'uint256', name: '_mintFeeAmount', type: 'uint256' }],
    stateMutability: 'nonpayable',
    type: 'constructor',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'owner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'approved', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }],
    name: 'Approval',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'owner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'operator', type: 'address',
    }, {
      indexed: false, internalType: 'bool', name: 'approved', type: 'bool',
    }],
    name: 'ApprovalForAll',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }],
    name: 'Burn',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousERC20', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newERC20', type: 'address',
    }],
    name: 'FeeERC20AddressTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'MintFeeAddressTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'previousOwner', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'newOwner', type: 'address',
    }],
    name: 'OwnershipTransferred',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'Paused',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
    }, {
      indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32',
    }, {
      indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32',
    }],
    name: 'RoleAdminChanged',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
    }, {
      indexed: true, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }],
    name: 'RoleGranted',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
    }, {
      indexed: true, internalType: 'address', name: 'account', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'sender', type: 'address',
    }],
    name: 'RoleRevoked',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'creator', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }],
    name: 'SetCreator',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'seller', type: 'address',
    }, {
      indexed: false, internalType: 'uint256', name: 'oldMintFeeAmount', type: 'uint256',
    }, {
      indexed: false, internalType: 'uint256', name: 'newMintFeeAmount', type: 'uint256',
    }],
    name: 'SetMintFeeAmount',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'setter', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }, {
      indexed: true, internalType: 'uint256', name: 'royalties', type: 'uint256',
    }],
    name: 'SetRoyalties',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: true, internalType: 'address', name: 'from', type: 'address',
    }, {
      indexed: true, internalType: 'address', name: 'to', type: 'address',
    }, {
      indexed: true, internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }],
    name: 'Transfer',
    type: 'event',
  }, {
    anonymous: false,
    inputs: [{
      indexed: false, internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'Unpaused',
    type: 'event',
  }, {
    inputs: [],
    name: 'ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'PAUSED_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'UPDATE_TOKEN_URI_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }],
    name: 'approve',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'baseURI',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'burn',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'feeERC20Address',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'getApproved',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'uint256', name: 'index', type: 'uint256',
    }],
    name: 'getRoleMember',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleMemberCount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'hasRole',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, {
      internalType: 'address', name: 'operator', type: 'address',
    }],
    name: 'isApprovedForAll',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'string', name: '_tokenURI', type: 'string',
    }],
    name: 'mint',
    outputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'to', type: 'address' }, {
      internalType: 'string', name: '_tokenURI', type: 'string',
    }, {
      internalType: 'uint256', name: '_tokenRoyalties', type: 'uint256',
    }, { internalType: 'address payable', name: '_creator', type: 'address' }],
    name: 'mintByCreator',
    outputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    stateMutability: 'payable',
    type: 'function',
  }, {
    inputs: [],
    name: 'mintFeeAddr',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'mintFeeAmount',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'nextTokenId',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'ownerOf',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [], name: 'pause', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, {
    inputs: [],
    name: 'paused',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'quoteErc20',
    outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, {
      internalType: 'address', name: 'account', type: 'address',
    }],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [],
    name: 'royaltiesTop',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'from', type: 'address' }, {
      internalType: 'address', name: 'to', type: 'address',
    }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'from', type: 'address' }, {
      internalType: 'address', name: 'to', type: 'address',
    }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }, {
      internalType: 'bytes', name: '_data', type: 'bytes',
    }],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'operator', type: 'address' }, {
      internalType: 'bool', name: 'approved', type: 'bool',
    }],
    name: 'setApprovalForAll',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'string', name: 'baseURI', type: 'string' }],
    name: 'setBaseURI',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_mintFeeAmount', type: 'uint256' }],
    name: 'setMintFeeAmount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_royalties', type: 'uint256' }],
    name: 'setRoyaltiesTop',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'uint256', name: '_tokenId', type: 'uint256',
    }, { internalType: 'address payable', name: '_creator', type: 'address' }],
    name: 'setTokenCreator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'uint256', name: '_tokenId', type: 'uint256',
    }, { internalType: 'uint256', name: '_royalties', type: 'uint256' }],
    name: 'setTokenRoyalties',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{
      internalType: 'uint256', name: 'tokenId', type: 'uint256',
    }, { internalType: 'string', name: 'tokenURI', type: 'string' }],
    name: 'setTokenURI',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'index', type: 'uint256' }],
    name: 'tokenByIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'tokenCreator',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'tokenCreators',
    outputs: [{ internalType: 'address payable', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }, {
      internalType: 'uint256', name: 'index', type: 'uint256',
    }],
    name: 'tokenOfOwnerByIndex',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    name: 'tokenRoyalties',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: '_tokenId', type: 'uint256' }],
    name: 'tokenRoyaltiesById',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'tokenURI',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'tokensOfOwner',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: '_feeERC20Address', type: 'address' }],
    name: 'transferFeeERC20Address',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'from', type: 'address' }, {
      internalType: 'address', name: 'to', type: 'address',
    }, { internalType: 'uint256', name: 'tokenId', type: 'uint256' }],
    name: 'transferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address payable', name: '_mintFeeAddr', type: 'address' }],
    name: 'transferMintFeeAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  }, {
    inputs: [], name: 'unpause', outputs: [], stateMutability: 'nonpayable', type: 'function',
  }, { stateMutability: 'payable', type: 'receive' }],
};
