import api from '@/api/api';
import { ElMessage } from 'element-plus';
import store from '@/store/index';
export default {
  name: 'LoginBox',
  props: {
    address: {
      type: String,
      required: true
    },
    confirm: {
      type: Function
    },
    cancel: {
      type: Function
    }
  },

  data() {
    return {
      loading: false,
      form: {
        confirmPassword: null,
        password: null,
        username: null,
        address: null
      },
      rules: {
        username: [{
          required: true,
          message: 'Please enter email',
          trigger: 'blur'
        }, {
          type: 'email',
          message: 'Please enter the correct email format',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: 'Please enter password',
          trigger: 'blur'
        }],
        confirmPassword: [{
          required: true,
          message: 'Please enter password again',
          trigger: 'blur'
        }]
      }
    };
  },

  methods: {
    login() {
      this.$refs.form.validate().then(() => {
        this.loading = true;
        this.form.address = this.address;
        api.register(this.form).then(res => {
          this.loading = false;
          store.commit('setUsername', res.data.username);
          store.commit('setMyCode', res.data.myCode);
          this.confirm(res.data.myCode);
        }).catch(err => {
          ElMessage.error(err.msg);
          this.loading = false;
        });
      });
    }

  }
};